/* Common Imports */

import { styled } from "@mui/system";
import React from "react";

/* Component Imports */

import { useTheme } from "@mui/material";
import { useRouter } from "next/router";
import FooterCopyrightInfo from "./FooterCopyrightInfo";
import FooterLinks from "./FooterLinks";
import FooterSocialIcons from "./FooterSocialIcons";

/* Styled Components */

const Container = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
	alignItems: "center",
	width: "100%",
}));

const FooterHeader = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	width: "100%",
}));

const FooterContainer = () => {
	const router = useRouter();
	const theme = useTheme();

	return (
		<React.Fragment>
			<Container
				sx={{
					direction: router.locale === "ar-AE" ? "rtl" : "ltr",
				}}
			>
				{/* Footer Header */}

				<FooterHeader>
					{/* Logo */}

					<img
						src={
							theme.palette.mode === "dark"
								? "/images/logo/logo-white.svg"
								: router.locale === "ar-AE"
									? "/images/logo/logo_arabic.svg"
									: router.locale === "kn-IN"
										? "/images/logo/logo_kannada.svg"
										: "/images/logo/logo.svg"
						}
						alt="Beegru Pte Ltd"
						width={102}
						height={30}
						loading="lazy"
						referrerPolicy="no-referrer"
						style={{
							cursor: "pointer",
						}}
						onClick={() => {
							window.location.href = "/" + router.locale;
						}}
					/>

					{/* Social Icons */}

					<FooterSocialIcons />
				</FooterHeader>

				{/* Footer Links */}

				<FooterLinks />

				{/* Copyright */}

				<FooterCopyrightInfo />
			</Container>
		</React.Fragment>
	);
};

export default FooterContainer;
